<template>
    <div class="content-box">
        <div class="container">

   <div>
      <!-- <iframe class="content" src="https://meeting.tencent.com/" frameborder="0"></iframe> -->
      <iframe class="content" src="https://meeting.tencent.com/user-center/join" frameborder="0"></iframe>

      <!-- <hr> -->
      <!-- <iframe :src= url width="1000" height="500" frameborder="0" scrolling="auto"></iframe> -->
    </div>

        </div>
    </div>
</template>

<script>
export default {};
</script>

<style lang ='scss' scoped>
.content{
    margin-top: 20px;
  width: 80vw;
  height: 83vh;
}
</style>